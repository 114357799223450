import React from "react"

interface Props {
}

export const NotFound: React.FC<Props> = ({ }) => {
	return (
		<svg className="illustration" width="400" height="300" viewBox="0 0 400 300">
			<path id="cloud_1_" className="st0" d="M257.5,184.7c-1.7,0-3.3,0.1-5,0.2c1.8-5.1,2.8-10.6,2.8-16.4c0-26.9-21.8-48.7-48.7-48.7
	c-18.4,0-34.4,10.2-42.6,25.2c-3.5-1.3-7.2-2-11.1-2c-16.7,0-30.4,12.5-32.4,28.6c-6.3-3.4-13.6-5.4-21.3-5.4
	c-24.1,0-43.8,19.1-44.8,42.9c-4.5-1.8-9.4-2.7-14.5-2.7c-22.1,0-40,17.9-40,40s17.9,40,40,40h217.5c28.1,0,50.9-22.8,50.9-50.9
	C308.4,207.5,285.6,184.7,257.5,184.7z"/>
			<path id="cloud_4_" className="st1" d="M361.1,221.9c-1.3,0-2.6,0.1-3.8,0.2c1.4-3.9,2.2-8.2,2.2-12.6c0-20.6-16.7-37.4-37.4-37.4
	c-14.1,0-26.4,7.8-32.7,19.3c-2.7-1-5.5-1.5-8.5-1.5c-12.8,0-23.3,9.6-24.8,22c-4.9-2.6-10.4-4.1-16.3-4.1
	c-18.5,0-33.6,14.6-34.4,33c-3.5-1.3-7.2-2.1-11.1-2.1c-16.9,0-30.7,13.7-30.7,30.7c0,16.9,13.7,30.7,30.7,30.7h166.9
	c21.6,0,39-17.5,39-39C400.1,239.4,382.7,221.9,361.1,221.9z"/>
			<g>
				<path className="st2" d="M146.6,242.5h-20.7v-16.8H78.8v-19.1l34.1-72.3h23.5l-34.1,72.3h23.6v-27.1h20.7v27.1h9.6v19.1h-9.6V242.5z" />
				<path className="st2" d="M183.4,239.7c-6.1-3.3-10.8-8-14.1-14.1c-3.3-6.1-5-13.1-5-21v-32.5c0-7.9,1.7-14.9,5-21
		c3.3-6.1,8-10.8,14.1-14.1c6.1-3.3,13.1-5,21-5c7.9,0,14.9,1.7,21,5c6.1,3.3,10.8,8,14.1,14.1c3.3,6.1,5,13.1,5,21v32.5
		c0,7.9-1.7,14.9-5,21c-3.3,6.1-8,10.8-14.1,14.1c-6.1,3.3-13.1,5-21,5C196.5,244.7,189.5,243,183.4,239.7z M214.2,222.8
		c2.9-1.7,5.2-4,7-7c1.7-2.9,2.6-6.2,2.6-9.7v-35.6c0-3.6-0.9-6.8-2.6-9.7c-1.7-2.9-4-5.2-7-7c-2.9-1.7-6.2-2.6-9.7-2.6
		c-3.6,0-6.8,0.9-9.7,2.6c-2.9,1.7-5.3,4-7,7c-1.7,2.9-2.6,6.2-2.6,9.7v35.6c0,3.6,0.9,6.8,2.6,9.7c1.7,2.9,4,5.3,7,7
		c2.9,1.7,6.2,2.6,9.7,2.6C208,225.3,211.2,224.5,214.2,222.8z"/>
				<path className="st2" d="M320.4,242.5h-20.7v-16.8h-47.1v-19.1l34.1-72.3h23.5l-34.1,72.3h23.6v-27.1h20.7v27.1h9.6v19.1h-9.6V242.5z"
				/>
			</g>
			<g>
				<g>
					<path className="st3" d="M67.9,25.7c0.1-0.2,0.1-0.5,0.2-0.7c1.6-4.1,5.5-5.7,5.5-5.7c4.8-2.2,9.9,0,9.9,0c3.4-8.8,9.9-7.2,10.9-7
			c2.1-3.4,5.8-5.7,10.1-5.7c6.5,0,11.7,5.2,11.7,11.7c0,0.9-0.1,1.9-0.3,2.8c4,0.8,7,2.6,7.4,2.8c0-9.8-0.5-15.7-4.4-19.5
			C114.5,0,107.7,0,95.5,0c-12.1,0-19,0-23.3,4.4C68.1,8.7,67.7,14.9,67.9,25.7L67.9,25.7z"/>
					<path className="st3" d="M131.1,39.7c-2.1,1.9-4.9,2.9-7.6,3.7c-3,0.8-6.2,1.4-9.4,1.6c-5.1,0.5-10.2,0.1-14.9-2.3
			c-1.2-0.6-2.2-1.3-3.1-2.1c-0.1-0.1-1.2-0.9-1-0.9c-3.4,5.6-11,5.1-11,5.1c-6.5-0.1-9.8-6.2-9.8-6.2c0.1,0.1-3.1,1-3.4,1.2
			c-3.6,0.8-7.6,0.7-11.3,0.1c3.6,3,7.2,4.5,8.8,5.1c0.7,2.6,1.7,4.7,3.4,6.3c4.4,4.4,11.3,4.4,23.4,4.4c12.2,0,19,0,23.4-4.4
			c1.3-1.3,2.1-2.7,2.8-4.4C126.5,45.9,131.1,39.7,131.1,39.7z"/>
				</g>
				<g>
					<path className="st3" d="M143.1,22.1h-0.3c-0.8,0-1.4-0.6-1.4-1.4l0,0c0-0.8,0.6-1.4,1.4-1.4h0.3c0.8,0,1.4,0.6,1.4,1.4l0,0
			C144.5,21.5,143.9,22.1,143.1,22.1z M144.5,36.4h-3.1V23.8h3.1V36.4z"/>
					<path className="st3" d="M148,36.4V19.7h5.5c0.8,0,1.5,0,2,0.1c1.3,0.1,2.4,0.6,3.4,1.4s1.6,1.7,2.1,2.9s0.7,2.4,0.7,4s-0.2,2.8-0.7,4
			s-1.2,2.2-2.1,2.9s-2.1,1.2-3.4,1.4c-0.5,0.1-1.2,0.1-2,0.1H148L148,36.4z M153.4,33.5c0.8,0,1.4,0,1.9-0.1c0.7-0.1,1.3-0.5,1.7-1
			c0.5-0.6,0.8-1.2,1-1.9c0.2-0.8,0.3-1.5,0.3-2.3s-0.1-1.6-0.3-2.4c-0.2-0.8-0.6-1.4-1-1.9c-0.5-0.5-1-0.8-1.7-0.9
			c-0.5-0.2-1.2-0.2-1.9-0.2h-2.2v10.7L153.4,33.5L153.4,33.5z"/>
					<path className="st3" d="M171.1,23.8c0.2,0,0.5,0,0.7,0v2.9c-0.2-0.1-0.6-0.1-0.9-0.1c-0.6,0-1.2,0.1-1.5,0.3
			c-0.6,0.2-0.9,0.6-1.3,1.2c-0.2,0.5-0.5,1.2-0.5,1.9v6.4h-3.1V23.8h2.8v2c0.3-0.6,0.8-1.2,1.4-1.4
			C169.4,24.1,170.2,23.8,171.1,23.8z"/>
					<path className="st3" d="M176.5,31c0.1,0.9,0.5,1.6,0.9,2.1c0.5,0.5,1.2,0.7,2,0.7c0.6,0,1.2-0.1,1.6-0.5c0.5-0.2,0.8-0.7,1-1.2
			l3.1,0.9c-0.3,0.8-0.8,1.4-1.3,2c-0.6,0.6-1.2,0.9-2,1.3c-0.7,0.2-1.5,0.5-2.3,0.5c-1.3,0-2.4-0.2-3.4-0.8
			c-0.9-0.6-1.7-1.3-2.3-2.3c-0.6-1-0.8-2.1-0.8-3.4c0-1.4,0.2-2.6,0.8-3.6c0.6-1,1.3-1.9,2.2-2.4c0.9-0.6,2.1-0.8,3.3-0.8
			c1.2,0,2.2,0.2,3.1,0.8c0.9,0.5,1.5,1.3,2.1,2.3c0.5,0.9,0.7,2.1,0.7,3.5c0,0.2,0,0.5,0,0.9C185.3,31,176.5,31,176.5,31z
			 M176.6,28.7h5.5c-0.1-0.8-0.3-1.5-0.8-1.9c-0.5-0.3-1-0.6-1.7-0.6C177.9,26.3,176.9,27.1,176.6,28.7z"/>
					<path className="st3" d="M198.1,27.1c0.1,0.5,0.1,0.9,0.1,1.6v7.7h-2.8v-1.5c-0.6,0.7-1.2,1.2-1.9,1.4c-0.7,0.2-1.5,0.5-2.4,0.5
			c-0.8,0-1.6-0.1-2.2-0.5c-0.6-0.3-1.2-0.8-1.4-1.4c-0.2-0.6-0.5-1.2-0.5-2c0-0.9,0.2-1.7,0.6-2.3c0.5-0.6,1.2-1.2,2.1-1.5
			c0.6-0.2,1.2-0.3,2-0.5c0.8-0.1,1.9-0.3,3.4-0.6c-0.1-0.6-0.2-1-0.7-1.3c-0.3-0.2-0.9-0.5-1.6-0.5c-0.5,0-1,0.1-1.5,0.3
			c-0.5,0.2-0.8,0.6-0.9,1.2l-2.8-0.9c0.3-1,0.9-2,1.9-2.6c0.9-0.6,2.1-0.9,3.5-0.9c2.4,0,4.1,0.8,4.9,2.3
			C197.9,26.2,198,26.6,198.1,27.1z M195.1,30.7c-1,0.1-1.9,0.3-2.4,0.5c-0.5,0.1-0.9,0.2-1.3,0.3c-0.5,0.1-0.8,0.3-1,0.6
			c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.5,0.1,0.8,0.5,1c0.3,0.2,0.7,0.3,1.3,0.3c0.5,0,1-0.1,1.4-0.2c0.5-0.1,0.7-0.5,0.9-0.7
			c0.2-0.2,0.5-0.5,0.5-0.7C194.9,32.2,195.1,31.5,195.1,30.7z"/>
					<path className="st3" d="M217.5,24.2c0.7,0.3,1.2,0.9,1.5,1.6c0.3,0.7,0.5,1.5,0.5,2.4v8.3h-3.1v-7.4c0-0.8-0.2-1.5-0.6-2
			c-0.3-0.5-0.9-0.7-1.6-0.7c-0.5,0-0.8,0.1-1.2,0.3c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.3-0.2,0.8-0.2,1.3v7.6h-3.1V29
			c0-0.8-0.2-1.5-0.6-2c-0.3-0.5-0.9-0.7-1.6-0.7c-0.5,0-0.8,0.1-1.2,0.3c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.2,0.8-0.2,1.3v7.6
			h-3.1V23.8h2.8v1.4c0.5-0.5,0.9-0.9,1.6-1.2c0.6-0.2,1.3-0.5,2.1-0.5c0.8,0,1.6,0.1,2.2,0.5c0.6,0.3,1,0.8,1.4,1.4
			c0.5-0.6,0.9-1,1.6-1.4c0.7-0.3,1.4-0.5,2.2-0.5C216,23.6,216.8,23.8,217.5,24.2z"/>
					<path className="st3" d="M230.5,36.4h-3.1V19.7h3.1V36.4z" />
					<path className="st3" d="M244.1,24.7c0.7,0.8,1.2,1.5,1.4,2.4c0.1,0.8,0.2,1.6,0.2,2.3v7.1h-3.1v-5.9c0-0.6,0-1.2-0.1-1.9
			c-0.1-0.6-0.3-1-0.7-1.5c-0.3-0.5-1-0.7-1.9-0.7c-0.7,0-1.4,0.2-1.9,0.8c-0.5,0.5-0.8,1.5-0.8,2.8v6.4H234V23.8h2.8v1.3
			c0.5-0.5,1-0.9,1.7-1.2c0.7-0.2,1.5-0.5,2.3-0.5C242.3,23.5,243.4,23.8,244.1,24.7z"/>
					<path className="st3" d="M252.6,26.3v4.5v0.7c0,0.5,0,0.7,0,0.9c0,0.2,0.1,0.5,0.2,0.6c0.3,0.5,0.9,0.8,1.9,0.8c0.3,0,0.8,0,1.5-0.1
			v2.7c-0.8,0.1-1.5,0.2-2.2,0.2c-0.9,0-1.7-0.1-2.4-0.5c-0.7-0.2-1.2-0.7-1.5-1.4c-0.2-0.3-0.3-0.8-0.5-1.2
			c-0.1-0.5-0.1-0.8-0.1-1.4V31v-4.8h-2.1v-2.4h2.1v-3.5h3.1v3.5h3.6v2.4H252.6z"/>
					<path className="st3" d="M261,31c0.1,0.9,0.5,1.6,0.9,2.1s1.2,0.7,2,0.7c0.6,0,1.2-0.1,1.6-0.5c0.5-0.2,0.8-0.7,1-1.2l3.1,0.9
			c-0.3,0.8-0.8,1.4-1.3,2c-0.6,0.6-1.2,0.9-2,1.3c-0.7,0.2-1.5,0.5-2.3,0.5c-1.3,0-2.4-0.2-3.4-0.8c-0.9-0.6-1.7-1.3-2.3-2.3
			c-0.6-0.9-0.8-2.1-0.8-3.4c0-1.4,0.2-2.6,0.8-3.6s1.3-1.9,2.2-2.4c0.9-0.6,2.1-0.8,3.3-0.8c1.2,0,2.2,0.2,3.1,0.8
			c0.9,0.5,1.5,1.3,2.1,2.3c0.5,0.9,0.7,2.1,0.7,3.5c0,0.2,0,0.5,0,0.9C269.8,31,261,31,261,31z M261.1,28.7h5.5
			c-0.1-0.8-0.3-1.5-0.8-1.9c-0.5-0.3-1-0.6-1.7-0.6C262.5,26.3,261.5,27.1,261.1,28.7z"/>
					<path className="st3" d="M279.1,23.8c0.2,0,0.5,0,0.7,0v2.9c-0.2-0.1-0.6-0.1-0.9-0.1c-0.6,0-1.2,0.1-1.5,0.3
			c-0.6,0.2-0.9,0.6-1.3,1.2c-0.2,0.5-0.5,1.2-0.5,1.9v6.4h-3.3V23.8h2.8v2c0.3-0.6,0.8-1.2,1.4-1.4
			C277.3,24.1,278.1,23.8,279.1,23.8z"/>
					<path className="st3" d="M292.3,27.1c0.1,0.5,0.1,0.9,0.1,1.6v7.7h-2.8v-1.5c-0.6,0.7-1.2,1.2-1.9,1.4c-0.7,0.2-1.5,0.5-2.4,0.5
			c-0.8,0-1.6-0.1-2.2-0.5c-0.6-0.3-1.2-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.9,0.2-1.7,0.6-2.3c0.5-0.6,1.2-1.2,2.1-1.5
			c0.6-0.2,1.2-0.3,2-0.5c0.8-0.1,1.9-0.3,3.4-0.6c-0.1-0.6-0.2-1-0.7-1.3c-0.3-0.2-0.9-0.5-1.6-0.5c-0.5,0-1,0.1-1.5,0.3
			c-0.5,0.2-0.8,0.6-0.9,1.2l-2.8-0.9c0.3-1,0.9-2,1.9-2.6c0.9-0.6,2.1-0.9,3.5-0.9c2.4,0,4.1,0.8,4.9,2.3
			C292,26.2,292.2,26.6,292.3,27.1z M289.1,30.7c-1,0.1-1.9,0.3-2.4,0.5c-0.5,0.1-0.9,0.2-1.3,0.3c-0.5,0.1-0.8,0.3-1,0.6
			c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.5,0.1,0.8,0.5,1c0.3,0.2,0.7,0.3,1.3,0.3c0.5,0,1-0.1,1.4-0.2c0.5-0.1,0.7-0.5,0.9-0.7
			c0.2-0.2,0.5-0.5,0.5-0.7C289,32.2,289.1,31.5,289.1,30.7z"/>
					<path className="st3" d="M297.5,35.9c-0.9-0.6-1.6-1.4-2.1-2.3c-0.5-1-0.8-2.1-0.8-3.4s0.2-2.4,0.8-3.4c0.5-1,1.3-1.9,2.2-2.3
			c0.9-0.6,2.1-0.8,3.3-0.8c0.9,0,1.9,0.1,2.7,0.5c0.8,0.3,1.4,0.8,2,1.5c0.5,0.6,0.8,1.4,1,2.2l-3.1,0.8c-0.1-0.7-0.5-1.2-0.9-1.5
			s-0.9-0.6-1.5-0.6c-0.9,0-1.6,0.3-2.1,1c-0.5,0.7-0.7,1.5-0.7,2.7s0.2,2,0.7,2.7c0.5,0.7,1.2,1,2.1,1c0.7,0,1.2-0.1,1.6-0.5
			c0.3-0.3,0.7-0.8,0.8-1.4l3.3,0.7c-0.3,1.3-1,2.3-2,3.1c-1,0.7-2.2,1-3.7,1C299.6,36.7,298.4,36.5,297.5,35.9z"/>
					<path className="st3" d="M312.7,26.3v4.5v0.7c0,0.5,0,0.7,0,0.9c0,0.2,0.1,0.5,0.2,0.6c0.3,0.5,0.9,0.8,1.9,0.8c0.3,0,0.8,0,1.5-0.1
			v2.7c-0.8,0.1-1.5,0.2-2.2,0.2c-0.9,0-1.7-0.1-2.4-0.5c-0.7-0.3-1.2-0.7-1.5-1.4c-0.2-0.3-0.3-0.8-0.5-1.2
			c-0.1-0.5-0.1-0.8-0.1-1.4V31v-4.8h-2.1v-2.4h2.1v-3.5h3.1v3.5h3.6v2.4H312.7z"/>
					<path className="st3" d="M321.1,22.1h-0.3c-0.8,0-1.4-0.6-1.4-1.4l0,0c0-0.8,0.6-1.4,1.4-1.4h0.3c0.8,0,1.4,0.6,1.4,1.4l0,0
			C322.5,21.5,321.9,22.1,321.1,22.1z M322.5,36.4h-3.1V23.8h3.1V36.4z"/>
					<path className="st3" d="M332.6,36.4h-3.3l-4.5-12.6h3.1l3,8.7l3-8.7h3.1L332.6,36.4z" />
					<path className="st3" d="M341.5,31c0.1,0.9,0.5,1.6,0.9,2.1c0.5,0.5,1.2,0.7,2,0.7c0.6,0,1.2-0.1,1.6-0.5c0.5-0.2,0.8-0.7,1-1.2
			l3.1,0.9c-0.3,0.8-0.8,1.4-1.3,2s-1.2,0.9-2,1.3c-0.7,0.2-1.5,0.5-2.3,0.5c-1.3,0-2.4-0.2-3.4-0.8c-0.9-0.6-1.7-1.3-2.3-2.3
			c-0.6-0.9-0.8-2.1-0.8-3.4c0-1.4,0.2-2.6,0.8-3.6s1.3-1.9,2.2-2.4c0.9-0.6,2.1-0.8,3.3-0.8s2.2,0.2,3.1,0.8
			c0.9,0.5,1.5,1.3,2.1,2.3c0.5,0.9,0.7,2.1,0.7,3.5c0,0.2,0,0.5,0,0.9C350.3,31,341.5,31,341.5,31z M341.6,28.7h5.5
			c-0.1-0.8-0.3-1.5-0.8-1.9c-0.5-0.3-1-0.6-1.7-0.6C342.9,26.3,341.9,27.1,341.6,28.7z"/>
				</g>
			</g>
		</svg>
	)
}
