import React from "react"
import { Container } from "../components/layout/Container";
import { graphql } from "gatsby";
import { NotFound } from "../components/sections/notfound/NotFound";
import { Helm } from "../components/layout/Helm";
import { Button, ButtonColour, ButtonSize } from "../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
    }
}


export default ({ data }: PageData) => {
    return (
        <>
            <Helm pageTitle={"Not Found"} description={data.site.siteMetadata.description} />
            <Container className="not-found content-center">
                <div className="info text-center">
                    <NotFound />
                    <h2>Looks like you're lost.</h2>
                    <p>The page you're looking for is not available!</p>
                    <Button
                        buttonConfig={{
                            id: "blueGradient",
                            colour: ButtonColour.BLUE,
                            ctaLabel: "Home",
                            ctaLink: "/",
                            size: ButtonSize.LARGE,
                        }}
                    />
                </div>
            </Container>
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`